import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'
import titleMixin from './mixin/titleMixin'
import InputMask from 'vue-masked-input'
import VueYoutube from 'vue-youtube'
import Meta from 'vue-meta'
import { Dropdown } from 'floating-vue'

import 'floating-vue/dist/style.css'

Vue.use(Meta)
Vue.use(VueYoutube)
Vue.component('masked-input', InputMask)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})
Vue.mixin(titleMixin)
Vue.config.productionTip = false

Vue.component('VDropdown', Dropdown)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
