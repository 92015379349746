<template>
  <div class="header">
    <div class="container">
      <div class="navbar">
        <div class="logo">
          <router-link to="/">
            <img src="../../assets/img/logo.svg" alt="logo" />
          </router-link>
        </div>
        <TevaMenu v-if="!isMobile" :menu="menu" :active="active" class="menu" />
        <div
          type="button"
          class="burger-menu"
          :class="{ active: active }"
          @click="toggleMenu"
        >
          <div class="burger-bar burger-bar--1"></div>
          <div class="burger-bar burger-bar--2"></div>
          <div class="burger-bar burger-bar--3"></div>
        </div>
      </div>
      <TevaSubMenu v-if="!isMobile" :submenu="submenu" :active="active" />
      <div v-if="isMobile" class="mobile-menu" :class="{ active: active }">
        <TevaMenu :menu="menu" :active="active" />
        <TevaSubMenu :submenu="submenu" :active="active" />
      </div>
    </div>
  </div>
</template>

<script>
import TevaMenu from './TevaMenu.vue'
import TevaSubMenu from './TevaSubMenu.vue'

export default {
  name: 'TevaHeader',
  components: { TevaMenu, TevaSubMenu },
  data() {
    return {
      active: false,
      windowSize: 0,
      menu: [
        {
          title: 'Головные боли',
          route: '/headaches'
        },
        {
          title: 'Патогенез мигрени',
          route: '/migraine-pathogenesis'
        },
        {
          title: 'Клинические проявления',
          route: '/clinical-manifestations'
        },
        {
          title: 'Диагностика',
          route: '/diagnostics'
        },
        {
          title: 'Жизнь с мигренью',
          route: '/living-with-migraines'
        }
      ],
      submenu: [
        {
          title: 'Купирование приступов мигрени',
          route: '/relief-migraine'
        },
        {
          title: 'Профилактическое лечение',
          route: '/therapy'
        },
        {
          title: 'Международные рекомендации',
          route: '',
          files: [
            {
              name: 'Рекомендации Европейской Федерации головной боли',
              path: 'pdfs/Рекомендации Европейской Федерации головной боли.pdf'
            },
            {
              name: 'Рекомендации Американского Общества головной боли',
              path: 'pdfs/Рекомендации Американского Общества головной боли.pdf'
            },
            {
              name: 'Клинический протокол диагностики и терапии',
              path: 'pdfs/Клинический протокол диагностики и терапии.pdf'
            }
          ]
        },
        {
          title: 'Обучение',
          route: '/training'
        }
      ]
    }
  },
  computed: {
    isMobile() {
      return this.windowSize < 768
    }
  },
  mounted() {
    this.windowSize = window.innerWidth
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    toggleMenu() {
      this.active = !this.active
    },
    resizeHandler(e) {
      this.windowSize = e.target.innerWidth
    }
  },
  watch: {
    $route() {
      this.active = false
    }
  }
}
</script>

<style>
.v-popper__arrow-container {
  display: none !important;
}
</style>

<style scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 50;
  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 15%);
}
.navbar {
  height: 5.625rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: 20px;
}
.menu {
  justify-self: end;
}

.burger-menu {
  justify-self: end;
  position: relative;
  width: 40px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  height: 2px;
  border-radius: 2.5px;
  background-color: #00567a;
  position: absolute;
  right: 9px;
  left: 9px;
  width: auto;
  margin-top: -1px;
  transition:
    transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
  top: 14px;
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(1);
  top: 15px;
}

.burger-bar--3 {
  transform: translateY(6px);
  top: 16px;
}

.burger-menu.active {
  transform: rotate(-180deg);
}

.burger-menu.active .burger-bar--1 {
  transform: rotate(45deg);
  top: 50%;
}

.burger-menu.active .burger-bar--2 {
  opacity: 0;
}

.burger-menu.active .burger-bar--3 {
  transform: rotate(-45deg);
  top: 50%;
}

@media (min-width: 320px) and (max-width: 767px) {
  .header a {
    font-size: 18px;
  }
  .navbar {
    height: 5.5rem;
  }
  .navbar img {
    width: 100px;
  }
  .mobile-menu {
    opacity: 0;
    visibility: hidden;

    position: fixed;
    background-color: white;
    top: 5.5rem;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in;

    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 32px;
  }
  .mobile-menu.active {
    opacity: 1;
    visibility: visible;
  }
}
</style>
