<template>
  <ul ref="navMenu" class="nav-menu" :class="{ active: active }">
    <li v-for="link in menu" :key="link.title" class="nav-link">
      <router-link :to="link.route">{{ link.title }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    menu: Array,
    active: Boolean
  }
}
</script>

<style scoped>
.nav-menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.nav-link a {
  color: #00567a;
}
.nav-link a.router-link-exact-active {
  color: #4e9e45;
}
@media (min-width: 320px) and (max-width: 767px) {
  .nav-menu {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
}
</style>
