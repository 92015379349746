<template>
  <!--wrap начало-->
  <div id="wrap">
    <header>
      <section
        v-show="false"
        class="announce"
        :class="{ active: !collapseOpen }"
      >
        <div class="container">
          <div class="announce__fl">
            <div class="announce__head">
              <h2 class="announce__title">Онлайн лонч-конференция "Аджови"</h2>
              <p>Онлайн конференция с участием экспертов</p>
            </div>
            <div class="buttons">
              <a href="https://ajovylaunch.kz/" target="_blank" class="button"
                >Регистрация</a
              >
              <button class="collapse-btn" @click="collapse">
                <img src="img/col-arrow.svg" alt="icon" />
              </button>
            </div>
          </div>
          <div class="announce__info">
            <p>
              Наконец-то в Казахстане! Лонч-конференция по "Аджови", на которой
              будут рассмотрены вопросы о профилактическом лечении мигрени с
              применением фреманезумаба (Аджови)
            </p>
            <div class="dates">
              <p>
                <img src="img/an-calendar.svg" alt="calendar" /> Дата:
                <span>03 - 04 ноября</span>
              </p>
              <p>
                <img src="img/an-time.svg" alt="time" /> Время:
                <span>18:00 - 20:00 </span>
              </p>
            </div>
          </div>
        </div>
        <div class="announce-person">
          <img src="img/announce-head.svg" alt="person" class="person-head" />
          <img src="img/person-pain.png" alt="person" class="person-pain" />
        </div>
      </section>
    </header>
    <TevaHeader />
    <router-view />

    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="firm-contacts">
              <div class="tel">
                <a href="tel:+77273251615"> +7 (727) 325-16-15</a>
              </div>
              <div class="mail">
                <a href="mailto:info.tevakz@tevapharm.com"
                  >info.tevakz@tevapharm.com</a
                >
              </div>
              <div class="address">
                Республика Казахстан, 050059, г.Алматы, Бостандыкский район,
                проспект <br class="visible-xs" />
                Аль-Фараби, дом 17/1, блок 5Б, 6-й этаж.
              </div>
              <div class="more-info">
                Подробную информацию о препаратах Teva вы можете узнать на нашем
                сайте
                <a href="http://kaz.teva" target="_blank">kaz.teva</a>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="politics">
              <p class="p1">
                <router-link to="/privacy">
                  Политика конфиденциальности</router-link
                >
              </p>
              <p class="p2">
                <router-link to="/statement">
                  Заявление о порядке обработки персональных данных компанией
                  «Тева»: <br class="visible-xs" />
                  Надзор за безопасностью лекарственных препаратов
                  (фармаконадзор и контроль качества)</router-link
                >
              </p>

              <p class="p3">
                <a
                  href="https://www.kaz.teva/ru/kazakhstan/contact-us/contact-us-form/"
                  target="_blank"
                  style="color: #afcb37"
                >
                  Сообщить о нежелательном явлении
                </a>
              </p>
              <p class="p4">
                <router-link to="/terms-of-use">
                  Условия доступа к интернет-ресурсу и условия пользования
                  интернет-ресурсом</router-link
                >
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="co d-block d-sm-none">NPS-KZ-00186</div>
            <a href="http://rocketfirm.com" class="rocket-link" target="_blank">
              <img src="img/logo-2024.svg" alt="logo" />
            </a>
          </div>
        </div>
        <div class="co hidden-xs">NPS-KZ-00186</div>
      </div>
    </footer>
    <div class="cookies" :class="{ hidden: isCookieEnabled }">
      <div class="container">
        <div class="desc">
          <div class="text">
            Данный Сайт использует cookie-файлы в целях предоставления вам
            лучшего пользовательского опыта на нашем Сайте.<br />
            Продолжая использовать данный Сайт, <br class="visible-xs" />
            вы соглашаетесь с использованием нами cookie-файлов.<br
              class="hidden-xs"
            />
            Для получения дополнительной информации см. наше
            <br class="visible-xs" />
            <a href="#">Уведомление о конфиденциальности и файлах cookie</a>
          </div>
          <button class="button-link js-cookies-btn" @click="cookieHide()">
            Ок
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- /wrap конец-->
</template>

<script>
import Cookies from 'js-cookie'
import TevaHeader from './components/TevaHeader/index.vue'

export default {
  components: { TevaHeader },
  data: function () {
    return {
      active: false,
      collapseOpen: false
    }
  },
  computed: {
    isCookieEnabled() {
      return Cookies.get('teva-cookie')
    }
  },
  methods: {
    toggleMenu() {
      this.active = !this.active
    },
    collapse() {
      this.collapseOpen = !this.collapseOpen
    },
    cookieHide() {
      Cookies.set('teva-cookie', true)
    }
  },
  mounted() {
    // don't remove this code

    // const announce = document.querySelector('.announce')
    // if (window.location.href.indexOf('https://migraine.tevaneuro.kz/') > -1) {
    //   announce.style.display = 'none'
    // }

    if (window.location.href !== 'https://teva-ajovy.rocketfirm.net/') {
      if (document.referrer === 'https://tevaneuro.kz/') {
        sessionStorage.setItem('redirect', '1')
      }

      if (sessionStorage.getItem('redirect') !== '1') {
        window.location.href = 'https://tevaneuro.kz/'
      }

      sessionStorage.setItem('redirect', '1')
    }
  }
}
</script>

<style>
a.router-link-exact-active {
  color: #00567a;
}

footer a.router-link-exact-active {
  color: #afcb37;
}
.announce {
  background: #00567a;
  color: #fff;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  font-family: notosans-light;
  font-size: 16px;
}

p.link {
  margin: 15px 0 0;
}

p.link a {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 600px) {
  p.link {
    width: 70%;
  }
  .announce {
    padding: 13px 0;
    transition: 0.8s;
  }

  .announce.active {
    padding: 15px 0 45px;
  }
}

.announce__fl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.announce__title {
  color: #fff;
  font-size: 26px;
  margin-bottom: 0.35rem;
  font-family: notosans-medium;
}

@media (max-width: 1100px) {
  .announce__title {
    font-size: 24px;
  }
}

@media (max-width: 800px) {
  .announce__title {
    font-size: 20px;
    width: 90%;
  }
}

@media (min-width: 600px) {
  .sm-hidden {
    display: none;
  }
}

@media (max-width: 800px) {
  .announce__title {
    line-height: normal;
    margin-bottom: 0;
  }
}

.announce__info {
  width: 62%;
  line-height: 170%;
  max-height: 0px;
  padding: 0;
  opacity: 0;
  transition: 0.8s;
}

.announce.active .announce__info {
  padding-top: 28px;
  opacity: 1;
  max-height: 500px;
}
.announce__info .dates {
  margin-top: 22px;
}
.announce__info p {
  font-size: 20px;
}

@media (max-width: 800px) {
  .announce__info {
    width: 100%;
  }
  .announce__info p {
    font-size: 16px;
    line-height: normal;
  }

  .announce.active .announce__info {
    padding-top: 15px;
  }
}
.dates p {
  display: flex;
  align-items: center;
  font-family: notosans-medium;
  font-size: 16px;
  margin-bottom: 0;
}

.dates p img {
  margin-right: 0.438rem;
}

.dates p span {
  font-family: notosans-light;
  margin-left: 5px;
}
.announce__head .buttons {
  position: relative;
  z-index: 5;
}
.announce__head p {
  margin: 0;
  opacity: 0.6;
}

@media (max-width: 800px) {
  .announce__head {
    width: 100%;
  }
  .announce__head p {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .announce__head p {
    opacity: 0;
    max-height: 0px;
    transition: 0.3s;
  }

  .active .announce__head p {
    max-height: 100px;
    opacity: 0.6;
  }

  .announce__info .dates {
    margin-top: 45px;
  }
}

.buttons .button {
  color: #003f77;
  font-size: 16px;
}

@media (max-width: 800px) {
  .buttons {
    margin-top: 15px;
  }
  .collapse-btn {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

@media (max-width: 600px) {
  .buttons .button {
    font-size: 12px;
    display: inline-block;
  }
}

.buttons .button:hover {
  background-color: #aa198d;
  color: #fff;
}
.collapse-btn {
  background: none;
  border: none;
  outline: none;
  margin-left: 35px;
  transition: 0.3s;
}

.announce.active .collapse-btn {
  transform: rotate(180deg);
}

.announce-person {
  position: absolute;
  right: 25%;
  bottom: -150%;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: 0.8s;
}

.announce.active .announce-person {
  opacity: 1;
  bottom: 0;
}

img.person-pain {
  position: absolute;
  top: 16px;
  left: 45px;
  animation: shadow 1s linear infinite alternate-reverse;
}

@media (max-width: 1100px) {
  .announce-person {
    right: 15%;
  }
}

@media (max-width: 800px) {
  .announce-person {
    right: 10px;
    height: 123px;
    width: 111px;
  }
  img.person-head {
    width: 100%;
    height: 100%;
    top: 0;
  }
  img.person-pain {
    width: 57px;
    left: 25px;
  }
}

@keyframes shadow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}
</style>
