<template>
  <div class="nav-submenu-container" :class="{ active: active }">
    <ul class="nav-submenu">
      <li v-for="link in submenu" :key="link.title" class="nav-link">
        <router-link v-if="link.route" :to="link.route">
          {{ link.title }}
        </router-link>
        <VDropdown
          v-else
          class="submenu-item"
          placement="bottom-end"
          :distance="12"
        >
          <a href="#" class="submenu-link">{{ link.title }}</a>
          <template #popper>
            <div v-if="link.files" class="dropdown-container">
              <ul class="dropdown-list">
                <li v-for="file in link.files" :key="file.path">
                  <i class="pdf-icon"></i>
                  <a :href="file.path" target="_blank">
                    {{ file.name }}
                  </a>
                </li>
              </ul>
            </div>
          </template>
        </VDropdown>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    submenu: Array,
    active: Boolean
  }
}
</script>

<style scoped>
.nav-submenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  padding: 0px 72px 24px 0px;
}

.nav-submenu-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}
.nav-submenu-container.active {
  max-height: 100px;
}
.nav-link a {
  color: #00567a;
}
.nav-link a.router-link-exact-active {
  color: #4e9e45;
}
.submenu-link {
  position: relative;
  padding-right: 16px;
}
.submenu-link::after {
  content: url('../../assets/img/cal-down.svg');
  position: absolute;
  top: 0px;
  width: 7px;
  margin-left: 8px;
  transition: transform 0.3s ease-in-out;
  transform-origin: 50% 65%;
}

.v-popper--shown .submenu-link::after {
  transform: rotate(180deg);
}

.dropdown-list {
  background-color: #ffffff;
  min-width: 220px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.dropdown-list li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 6px;
}
.pdf-icon {
  display: inline-block;
  width: 18px;
  min-width: 18px;
  height: 20px;
  background: url('../../assets/img//pdf.svg') 0 2px no-repeat;
  background-size: 16px 18px;
}

.dropdown-list li a {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: none;
  color: #4e9e45;
  font-size: 1rem;
  line-height: 1.3125rem;
}

.dropdown-list li a:hover {
  color: #00567a;
}

@media (min-width: 320px) and (max-width: 767px) {
  .nav-submenu-container.active {
    max-height: fit-content;
  }
  .nav-submenu {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 0px;
  }
  .dropdown-list li a {
    font-size: 13px;
    left: 14px;
  }
}
</style>
